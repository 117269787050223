@import "../../assets/styles/utils";

.about-banner{
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include res(height, 565px, 400 / 565);
  &.active{
    .container .cont h1::after{
      transform: scaleX(1);
    }
  }
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    // background-color: rgba(0,0,0,.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }
  .banner-mask{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: $color-blue-dark;
    opacity: 0;
  }
  .bg-img{
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
  }
  .container{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 100%;
    .cont{
      width: 100%;
      @include res(margin-bottom, 50px, 70 / 50);
      h1{
        color: $color-green;
        display: flex;
        align-items: center;
        line-height: 1.3;
        text-align: center;
        flex-direction: row-reverse;
        font-weight: bold;
        @include res(font-size, 50px, 36 / 50);
        &.white{
          color: #fff;
        }
        &::after{
          content: '';
          display: block;
          flex-grow: 1;
          height: 1px;
          background-color: rgba(255,255,255,.3);
          transition: transform 1s $anime-bezier;
          transform: scaleX(0);
          transform-origin: right;
          @include res(margin-right, 20px, 10 / 20);
        }
      }
    }
  }
}