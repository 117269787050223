@import "../../assets/styles/utils";

.law-observe-banner{
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  overflow: hidden;
  @include res(height, 570px, 500 / 570);
  @include res(padding-bottom, 90px, 180 / 90);
  &.has-bg{
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba($color-blue-dark,.6);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
    }
  }
  &.active{
    .title{
      &::before,&::after{
        transform: scaleX(1);
      }
    }
  }
  .banner-mask{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: $color-blue-dark;
    opacity: 0;
  }
  .bg-img{
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
  }
  .crumbs{
    @include res(margin-bottom,55px, 20 / 55);
  }
  .title{
    width: 90%;
    max-width: 910px;
    position: relative;
    line-height: 1.15;
    margin: 0 auto;
    @include res(padding-top,30px, 15 / 30);
    @include res(padding-bottom,30px, 15 / 30);
    &::before,&::after{
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: rgba(255,255,255,.3);
      transform-origin: center;
      position: absolute;
      left: 0;
      transform: scaleX(0);
      transition: transform 1.2s $anime-bezier;
    }
    &::before{
      top: 0;
    }
    &::after{
      bottom: 0;
    }
    .cn-title{
      color: $color-green;
      font-weight: bold;
      @include res(font-size,50px, 36 / 50);
    }
    .en-title{
      font-family: 'itc-md';
      color: #fff;
      @include res(font-size,28px, 16 / 28);
    }
  }
}