@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'itc-bk';
  src: url('../fonts/ITCAvantGardePro-Bk.woff') format('woff'),
    url('../fonts/ITCAvantGardePro-Bk.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'itc-md';
  src: url('../fonts/ITCAvantGardePro-Md.woff') format('woff'),
    url('../fonts/ITCAvantGardePro-Md.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'itc-dm';
  src: url('../fonts/ITCAvantGardePro-Demi.woff') format('woff'),
    url('../fonts/ITCAvantGardePro-Demi.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'lato-regular';
  src: url('../fonts/Lato-Regular.woff') format('woff'),
    url('../fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}

body {
  font-family: "lato-regular","PingFang SC","Microsoft YaHei";
  overflow-x: hidden;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(line-height, 1.8, 2 / 1.8);
  @include res(font-size, 16px);
}
em{
  font-style: normal;
}

.clear {
  zoom: 1;
  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}

.comp-root{
  overflow-x: hidden;
}
.position-rlt{
  position: relative;
}

.container {
  box-sizing: content-box;
  width: 100%;
  max-width: 1440px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

  &.container-1200 {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    box-sizing: border-box;
    max-width: 1330px;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 700px;
  }
}


// 列表中图片有占位图片的
.haszw-img{
  position: relative;
  overflow: hidden;
  img{
    display: block;
    width: 100%;
    height: auto;
    opacity: 0;
  }
  b,a{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    transition: all .3s;
  }
}

//文字位移效果
.ani-y{
  display: inline-block;
  transition: transform 1s $anime-bezier;
  transform: translateY(calc(100% + 2px));
}

.per200{
  perspective: 200px;
  .ani-z{
    display: inline-block;
    overflow: hidden;
    transition: transform 1s $anime-bezier;
    transform: translate3d(0,0,20px);
  }
}

.show,.active{
  .ani-y{
    transform: translateY(-5px);
  }
  @media screen and (max-width: 991px) {
    .ani-y{
      transform: translateY(0px);
    }
  }
  .per200{
    .ani-z{
      transform: translate3d(0,0,0);
    }
  }
}

//链接横线划过效果
.ani-line{
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding-bottom: 2px;
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: $color-green;
    position: absolute;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .4s $anime-bezier;
  }
  &.active,&.on{
    &::after{
      transform-origin: left;
      transform: scaleX(1);
    }
  }
  &:hover{
    &::after{
      @include res(transform-origin,left,(md:right));
      @include res(transform,scaleX(1),(md:scaleX(0)));
    }
  }
}

.ani-line-two{
  position: relative;
  cursor: pointer;
  padding-bottom: 2px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  display: inline-block;
  transition: color .7s $anime-bezier;
  @include res(font-size, 14px, 12 / 14);
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: $color-green;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  &:hover,&.active{
    color: $color-green;
    &::after{
      animation: anilinetwo .7s $anime-bezier;
    }
  }
}

@keyframes anilinetwo {
  0%{
    transform-origin: right;
    transform: scaleX(1);
  }
  50%{
    transform-origin: right;
    transform: scaleX(0);
  }
  51%{
    transform-origin: left;
    transform: scaleX(0);
  }
  100%{
    transform-origin: left;
    transform: scaleX(1);
  }
}

//面包屑
.crumbs{
  color: rgba(255,255,255,.5);
  @include res(font-size, 14px);
  @include res(margin-bottom,10px, 15 / 10);
  @include res(margin-top,10px, 15 / 10);
  span{
    display: inline-block;
    margin: 0 3px;
  }
  a{
    transition: color .7s $anime-bezier;
    &:hover{
      color: #fff;
    }
  }
}

//更多按钮
.comCircle {
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  @include res(width, 60px,(sm:50px,xs:40px));
  @include res(height, 60px,(sm:50px,xs:40px));
  &:hover{
    .circle{
      animation: c-arw-btn-circle-ov 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0s forwards
    }
    .icon{
      .iconfont{
        transform: translateY(100%);
      }
    }
  }
  .circle {
    color: #fff;
    animation: c-arw-btn-circle 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    transform: rotate(-90deg);
    position: absolute;
    top: -1px;
    right: 0;
    bottom: 0;
    left: -1px;
    stroke: #fff;
    stroke-width: 2px;
    stroke-dasharray: 200px 200px;
    stroke-dashoffset: 200px;
    fill: none;
    z-index: 10;
    @include res(width, 60px,(sm:50px,xs:40px));
    @include res(height, 60px,(sm:50px,xs:40px));
    circle{
      @include res(cx,30,(sm:25,xs:20px));
      @include res(cy,30,(sm:25,xs:20px));
      @include res(r,29,(sm:24,xs:19px));
    }
  }
  .icon{
    position: absolute;
    top: calc(50% - 6px);
    left: calc(50% - 13px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    @include res(width, 26px);
    @include res(height, 16px);
    .iconfont{
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      transition: transform 1s $anime-bezier;
      @include res(width, 26px);
      @include res(height, 16px);
    }
  }
}
.comCircle.color-green {
  border: 1px solid #cfd7e1;
  .icon .iconfont{
    color: #cfd7e1;
  }
  &:hover{
    .icon .iconfont{
      color: $color-green;
    }
  }
}
.comCircle.color-green .circle {
  color: $color-green;
  stroke: $color-green;
}
.comCircle.color-blue {
  border: 1px solid $color-blue;
  .icon .iconfont{
    color: $color-blue;
  }
  &:hover{
    .icon .iconfont{
      color: $color-blue;
    }
  }
}
.comCircle.color-blue .circle {
  color: $color-blue;
  stroke: $color-blue;
}
@keyframes c-arw-btn-circle {
  0% {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      stroke-dashoffset: 0
  }
  100% {
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
      stroke-dashoffset: -200px
  }
}
@keyframes c-arw-btn-circle-ov {
  0% {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      stroke-dashoffset: 200px
  }
  100% {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      stroke-dashoffset: 0
  }
}

.common-more-btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @include res(width,90px, 70 / 90);
  @include res(margin-top, 70px, 20 / 70);
  .ani-line-two{
    color: $color-green;
    @include res(margin-top, 15px, 10 / 15);
  }
}

//分页
.page-cont{
  align-items: center;
  justify-content: space-between;
  background-color: rgba($color-green, .1);
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  @include res(display, flex,(md:block));
  @include res(margin-top,30px);
  @include res(padding-top,10px, 10 / 10);
  @include res(padding-bottom,10px, 10 / 10);
  @include res(padding-left,28px, 10 / 28);
  @include res(padding-right,28px, 10 / 28);
  &.bg-white{
    color: $color-text;
    background-color: rgba($color-green, 0);
    @include res(padding-left,0);
    @include res(padding-right,0);
    .page{
      a{
        color: #000;
      }
    }
  }
  &.bg-gary{
    color: $color-text;
    background-color: rgba(255,255,255, 0.3);
    .page{
      a{
        color: #000;
      }
    }
  }
  .page{
    @include res(margin-top, 0,(sm:15px));
    a{
      display: inline-block;
      text-align: center;
      border-radius: 50%;
      transition: all .7s $anime-bezier;
      font-size: 14px;
      margin: 0 2px;
      @include res(line-height,36px, 30 / 36);
      @include res(width,36px, 30 / 36);
      @include res(height,36px, 30 / 36);
      &.active,&:hover{
        color: #fff;
        background-color: $color-green;
      }
    }
    ul{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      li{
        &:not(.xl-disabled):not(.xl-jumpButton):not(.xl-jumpText):not(.xl-prevPage):not(.xl-nextPage){
          display: inline-block;
          text-align: center;
          border-radius: 50%;
          transition: all .7s $anime-bezier;
          font-size: 14px;
          cursor: pointer;
          margin: 0 2px;
          @include res(line-height,36px, 30 / 36);
          @include res(width,36px, 30 / 36);
          @include res(height,36px, 30 / 36);
          &:hover{
            color: #fff;
            background-color: $color-green;
          }
        }
        &.xl-active{
          color: #fff;
          background-color: $color-green;
        }
        &.xl-prevPage,&.xl-nextPage{
          display: none;
        }
        &.xl-disabled{
          margin: 0 10px;
        }
        &.xl-jumpButton{
          cursor: pointer;
          transition: all .3s;
          &:hover{
            color: $color-green;
          }
        }
        &.xl-jumpText{
          input{
            width: 50px;
            @include res(margin,0 5px 0 15px,(xs:0 5px))
          }
        }
      }
    }
  }
}



//内页公共居中title
.ny-common-title{
  text-align: center;
  color: $color-green;
  font-weight: bold;
  letter-spacing: 0px;
  @include res(font-size,20px, 22 / 20);
  @include res(margin-bottom,100px, 30 / 100);
  &.white{
    color: #fff;
  }
}
//内页带横线的title
.ny-common-title-hasline{
  @include res(margin-bottom, 50px, 30 / 50);
  &.white{
    h1{
      span{
        color: #fff;
      }
      &::after{
        background-color: rgba(255, 255, 255,.2);
      }
    }
  }
  h1{
    display: flex;
    align-items: center;
    span{
      font-weight: bold;
      color: #000;
      letter-spacing: 0px;
      flex-shrink: 0;
      @include res(margin-right,25px, 15 / 25);
      @include res(font-size, 22px , 17 / 22);
    }
    &::after{
      content: '';
      display: block;
      flex-grow: 1;
      height: 1px;
      background-color: #cccccc;
    }
  }
}
//内页上下留白
.common-pd{
  @include res(padding-top,110px, 50 / 110);
  @include res(padding-bottom,100px, 50 / 100);
}

//公共搜索提示---start
.common-search-container{
  position: relative;
}
.common-search-hint{
  // display: none;
  position: absolute;
  top: calc(100% + 7px);
  left: 0;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all .7s $anime-bezier;
  box-shadow: 0 0 10px rgba(0,0,0,.2);
  border-radius: 25px;
  @include res(padding-top,35px, 15 / 35);
  @include res(padding-bottom,35px, 15 / 35);
  @include res(padding-right,40px, 15 / 40);
  @include res(padding-left,50px, 35 / 50);
  &.top{
    top: auto;
    bottom: calc(100% + 7px);
  }
  &.show{
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    &::after{
      transform: scaleY(1);
    }
  }
  &::after{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #fff;
    border-radius: 25px;
    top: 0;
    left: 0;
    transform-origin: top;
    transform: scaleY(.9);
    transition: transform .7s $anime-bezier;
  }
  .close-btn{
    position: absolute;
    cursor: pointer;
    padding: 10px;
    z-index: 2;
    @include res(top, 25px, 15 / 25);
    @include res(right, 25px, 15 / 25);
  }
  .hint-cont{
    .hint-item{
      &:not(:last-child){
        @include res(margin-bottom, 30px, 15 / 30);
      }
      .item-title{
        font-weight: bold;
        color: $color-blue-dark;
        transform: translateX(-20px);
        overflow: hidden;
        @include res(margin-bottom,15px, 10 / 15);
        @include res(font-size, 18px, 14 / 18);
      }
      .item-result{
        &.name{
          display: flex;
          a{
            display: inline-block;
            @include res(margin-right,30px, 15 / 30);
          }
        }
        a{
          overflow: hidden;
          display: block;
          pointer-events: all;
          transition: color .7s $anime-bezier;
          span{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
          // @include res(margin-bottom,)
          em{
            color: $color-green;
          }
          &:hover{
            color: $color-green;
          }
        }
      }
    }
  }
}
//公共搜索提示---end


// 列表页搜索条---start
.common-search-bar{
  .search-bar{
    background-color: $color-green;
    position: relative;
    display: flex;
    @include res(padding-top,0,(sm:10px));
    @include res(flex-direction,row,(sm:column-reverse));
    @include res(align-items,center);
    @include res(justify-content,space-between);
    @include res(padding-left,30px, 10 / 30);
    @include res(padding-right,30px, 10 / 30);
    .left{
      align-items: center;
      @include res(width,null,(xs:100%));
      @include res(display,flex,(xs:block));
      .left-title{
        color: rgba(255, 255, 255, 0.8);
        margin-right: 20px;
        @include res(display,null,(xs:none));
      }
      .left-nav{
        ul{
          flex-wrap: wrap;
          @include res(display,flex);
          li{
            @include res(min-width,auto,(xs:33.33%));
            &.show{
              .nav-title::after{
                transform: scaleX(1);
              }
              .sub-nav{
                opacity: 1;
                visibility: visible;
                pointer-events: all;
                &::after{
                  transform: scaleY(1);
                }
              }
            }
            &:not(:last-child){
              @include res(margin-right,20px, (md:15px,sm:10px,xs:0));
            }
            .nav-title{
              color: rgba(255, 255, 255, 0.8);
              display: flex;
              align-items: center;
              cursor: pointer;
              position: relative;
              @include res(justify-content,flex-start,(xs:center));
              @include res(height, 56px, 40 / 56);
              .iconfont{
                @include res(font-size, 20px, 16 / 20);
                @include res(margin-left, 5px, 0 / 5);
                @include res(margin-top, 3px, 0 / 3);
              }
              &::after{
                content: '';
                display: block;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
                background-color: #fff;
                transform-origin: center;
                transform: scaleX(0);
                transition: transform .7s $anime-bezier;
                @include res(height,2px);
              }
            }
            .sub-nav{
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              z-index: 10;
              opacity: 0;
              visibility: hidden;
              pointer-events: none;
              box-sizing: border-box;
              transition: opacity .7s $anime-bezier,visibility .7s $anime-bezier;
              @include res(padding-top,20px, 10 / 20);
              @include res(padding-bottom,30px, 10 / 30);
              @include res(padding-left,80px, 10 / 80);
              @include res(padding-right,40px, 5 / 40);
              &.name-letter{
                >div{
                  a{
                    @include res(width,3.8%,(sm:6%,xs:7.6%));
                  }
                }
              }
              >div{
                overflow-y: auto;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: flex-start;
                @include res(max-height,330px);
                @include res(padding-right,40px, 5 / 40);
                /*滚动条样式*/
                &::-webkit-scrollbar {
                  width: 4px;    
                }
                &::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                  background: rgba(#BDBDBD,.6);
                }
                &::-webkit-scrollbar-track {
                  box-shadow: inset 0 0 5px rgba(0,0,0,0);
                  border-radius: 0;
                  background: rgba(0,0,0,0);
                }
              }
              &::after{
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-color: #fff;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                transform: scaleY(.8);
                transform-origin: top;
                transition: transform .7s $anime-bezier;
              }
              a{
                display: block;
                color: #000;
                overflow: hidden;
                height: auto;
                @include res(width,25%,(xs:50%));
                @include res(margin-top,15px, 6 / 15);
                &.on{
                  .ani-line{
                    &::after{
                      transform-origin: left;
                      transform: scaleX(1);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .right{
      display: flex;
      align-items: center;
      @include res(width,null,(xs:100%));
      @include res(padding-bottom,null,(xs:5px));
      @include res(border-bottom,null,(xs:1px solid rgba(255,255,255,.2)));
      input{
        color: #fff;
        background-color: transparent;
        border: none;
        // @include res(flex-grow,null,(xs:1));
        @include res(width, 182px,(xs:calc(100% - 32px)));
        &::-webkit-input-placeholder{
          color: rgba(255, 255, 255, 0.8);
        }
        &:-moz-placeholder{
          color: rgba(255, 255, 255, 0.8);
        }
        &::moz-placeholder{
          color: rgba(255, 255, 255, 0.8);
        }
        &:-ms-input-placeholder{
          color: rgba(255, 255, 255, 0.8);
        }
      }
      button{
        color: rgba(255, 255, 255, 0.8);
        background-color: transparent;
        border: none;
        cursor: pointer;
        @include res(width,36px, 32 / 36);
        .iconfont{
          @include res(font-size, 24px, 20 / 24);
        }
      }
    }
  }
  .search-tag{
    display: flex;
    flex-wrap: wrap;
    // @include res(min-height, 32px, (md:0));
    @include res(line-height, 22px, 18 / 22);
    @include res(font-size, 14px, 12 / 14);
    @include res(margin-top, 25px, 10 / 25);
    .tag{
      background-color: $color-green;
      border-radius: 3px;
      color: #fff;
      display: flex;
      align-items: center;
      transition: all .7s $anime-bezier;
      @include res(margin-bottom, 10px, 5 / 10);
      @include res(padding-left,10px, 5 / 10);
      @include res(padding-right,8px, 5 / 8);
      &:not(:last-child){
        @include res(margin-right, 10px, 5 / 10);
      }
      .iconfont{
        margin-left: 5px;
        cursor: pointer;
        @include res(margin-left,5px, 3 / 5);
      }
    }
  }
}
// 列表页搜索条---end


//最新文章列表---start
.common-latest-news-list{
  ul{
    li{
      @include res(margin-bottom, 10px, 5 / 10);
      &:hover{
        a{
          color: $color-green;
        }
      }
      a{
        // display: flex;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        color: #000;
        transition: color .7s $anime-bezier,transform 1s $anime-bezier;
        span{
          color: $color-green;
          flex-shrink: 0;
          position: relative;
          display: inline-block;
          // @include res(width, 105px, 90 / 105);
          @include res(padding-right, 25px, 15 / 25);
          @include res(margin-right, 25px, 15 / 25);
          &::after{
            content: '';
            display: block;
            width: 1px;
            height: 16px;
            background-color: #b2b2b2;
            position: absolute;
            top: calc(50% - 8px);
            right: 0;
          }
        }
      }
    }
  }
}
//最新文章列表---end

//文康党建列表样式---start
.party-building-list{
  ul{
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    @include res(display,flex,(xs:block));
    li{
      display: none;
      flex-shrink: 0;
      @include res(width,46.2%,(xs:100%));
      @include res(margin-bottom,50px, 30 / 50);
      &:hover{
        .title>div{
          background-size: 100% 2px;
        }
      }
      .date{
        letter-spacing: 0px;
        @include res(font-size, 14px, 12 / 14);
      }
      .title{
        color: #000;
        line-height: 1.6;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        @include res(margin-top, 15px, 5 / 15);
        @include res(margin-bottom, 12px, 5 / 12);
        @include res(font-size, 22px, 18 / 22);
        >div{
          display: inline;
          background-image: linear-gradient(to bottom, $color-green, $color-green);
          background-position: 0 calc(100%);
          background-repeat: no-repeat;
          background-size: 0% 2px;
          animation-fill-mode: forwards;
          padding-bottom: 2px;
          transition: background-size 0.7s $anime-bezier;
        }
      }
      .desc{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
//文康党建列表样式---end

//法律观察公共swiper---start
.common-flgc-swiper{
  .swiper-slide{
    height: auto;
    a{
      position: relative;
      display: block;
      overflow: hidden;
      background-color: #000;
      height: 100%;
      &:hover{
        .title{
          width: calc(100% - 120px);
        }
        .link{
          transform: translateX(-100%);
        }
      }
      img{
        display: block;
        width: 100%;
        height: 100%;
      }
      .title{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        transition: width .7s $anime-bezier;
        @include res(font-size,26px, 18 / 26);
        span{
          overflow: hidden;
        }
      }
      .link{
        width: 120px;
        height: 100%;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 100%;
        background-color: $color-green;
        white-space: nowrap;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-weight: bold;
        letter-spacing: 0px;
        transition: transform .7s $anime-bezier;
        @include res(font-size, 14px, 12 / 14);
        @include res(padding, 20px, 10 / 20);
        .ani-line-two{
          color: #fff;
          &::after{
            background-color: #fff;
          }
        }
      }
      .more{
        height: 100%;
        background-color: $color-green;
        display: flex;
        align-items: center;
        justify-content: center;
        @include res(font-size, 18px, 16 / 18);
        &:hover{
          >div .iconfont{
            background-color: #fff;
            color: $color-green;
          }
        }
        >div{
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          letter-spacing: 0px;
          .iconfont{
            border: 1px solid #fff;
            border-radius: 50%;
            transition: all .3s;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: normal;
            letter-spacing: 0px;
            @include res(font-size, 22px, 20 / 22);
            @include res(margin-left,18px, 15 / 18);
            @include res(width,36px, 30 / 36);
            @include res(height,36px, 30 / 36);
            &::before{
              margin-top: 3px;
            }
          }
        }
      }
    }
  }
  .swiper-pagination{
    position: static;
    @include res(margin-top, 30px, 10 / 30);
    @include res(margin-bottom, 35px, 15 / 35);
    .swiper-pagination-bullet{
      background-color: #e8e8e8;
      opacity: 1;
      &.swiper-pagination-bullet-active{
        background-color: $color-green;
      }
    }
  }
}
//法律观察公共swiper---end



// 专业领域公共样式---start
// 专业领域第一部分
.field-section1{
  color: #000;
  @include res(margin-bottom,100px, 50 / 100);
  .container{
    align-items: flex-start;
    justify-content: space-between;
    @include res(display,flex,(sm:block));
    .left-text{
      flex-shrink: 0;
      box-sizing: border-box;
      @include res(padding-top,75px, 40 / 75);
      @include res(width,51.4%,(md:58%,sm:100%));
      h5{
        line-height: 1.4;
        @include res(font-size, 30px, 22 / 30);
        @include res(margin-bottom,40px, 35 / 40);
      }
      .t{
        @include res(font-size, 16px, (xs:18px));
        p{
          @include res(line-height, 2);
          @include res(margin-bottom, 35px);
        }
      }
      .link{
        @include res(text-align,null,(sm:center));
      }
    }
    .right-img{
      flex-shrink: 0;
      position: relative;
      z-index: 3;
      background-color: #000;
      @include res(margin,null,(sm:0 auto));
      @include res(width,37.5%,(sm:70%,xs:100%));
      @include res(margin-top, -160px,(lg:-140px,mmd:-120px,md:-100px,sm:40px));
      b{
        opacity: .8;
        filter: grayscale(50%);
      }
    }
  }
}
// 专业领域荣誉部分
.field-honor{
  background-color: $color-blue-dark;
  .ny-common-title-hasline{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include res(margin-bottom, 20px, 10 / 20);
    h1{
      flex-grow: 1;
    }
    .swiper-pagination{
      position: static;
      width: auto;
      @include res(margin-left,5px);
      .swiper-pagination-bullet{
        background-color: #fff;
        opacity: 1;
        &.swiper-pagination-bullet-active{
          background-color: $color-green;
        }
      }
    }
  }
}
//专业领域服务内容部分
.field-service{
  @include res(padding-bottom,100px, 20 / 100);
  .field-service-list{
    @include res(padding-left, 20px, 0 / 20);
    @include res(padding-right, 20px, 0 / 20);
    .title{
      font-weight: bold;
      color: #000;
      @include res(margin-bottom,30px, 15 / 30);
      @include res(font-size,17px, 16 / 17);
    }
    ul{
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      @include res(display,flex,(xs:block));
      &:not(:last-child){
        @include res(margin-bottom, 40px, 30 / 40);
      }
      li{
        flex-shrink: 0;
        box-sizing: border-box;
        border-left: 3px solid $color-green;
        line-height: 1.7;
        color: #000;
        @include res(font-size,16px, 16 / 16);
        @include res(width,29%,(sm:48%,xs:100%));
        @include res(padding-left,20px, 10 / 20);
        @include res(margin-bottom, 35px, 25 / 35);
      }
      .placeholder{
        @include res(width,29%,(sm:48%,xs:100%));
      }
    }
  }
}
//专业领域代表案例部分
.field-case{
  // @include res(padding-bottom,50px, 20 / 50);
  &.show-all{
    .field-case-list{
      // @include res(padding-bottom,50px, 20 / 50);
      ul li{
        display: block;
      }
    }
    .more-cont{
      display: none;
    }
  }
  .field-case-list{
    position: relative;
    @include res(padding-bottom, 100px, 40 / 100);
    @include res(padding-left, 20px, 0 / 20);
    @include res(padding-right, 20px, 0 / 20);
    ul{
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      @include res(display,flex,(xs:block));
      li{
        flex-shrink: 0;
        box-sizing: border-box;
        border-left: 3px solid $color-blue;
        line-height: 1.7;
        color: #000;
        position: relative;
        display: none;
        @include res(font-size,18px, 16 / 18);
        @include res(width,48%,(xs:100%));
        @include res(padding-left,20px, 15 / 20);
        @include res(margin-bottom, 35px, 25 / 35);
        &:nth-child(-n+6){
          display: block;
        }
        &:hover{
          &::after{
            transform: scaleY(1);
          }
          a{
            color: $color-green;
          }
        }
        &::after{
          content: '';
          display: block;
          width: 3px;
          height: 100%;
          background-color: $color-green;
          position: absolute;
          z-index: 1;
          top: 0;
          left: -3px;
          transform-origin: top;
          transform: scaleY(0);
          transition: transform .7s $anime-bezier;
        }
        a{
          display: block;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          transition: color .7s $anime-bezier;
        }
      }
    }
    .more-cont{
      // position: absolute;
      // bottom: 0;
      // left: 0;
      // width: 100%;
      // z-index: 2;
      // background-image: linear-gradient(rgba(255,255,255,.1),rgba(255,255,255,.8),rgba(255,255,255,1),rgba(255,255,255,1));
      // @include res(padding-top, 100px, 80 / 100);
      .common-more-btn .ani-line-two{
        color: #000;
      }
    }
  }
}
// 专业领域公共样式---end


//业务荣誉列表---start
.common-honor-list{
  .honor-list-item{
    position: relative;
    overflow: hidden;
    &::before{
      content: '';
      display: block;
      width: 35px;
      height: 100%;
      background: linear-gradient(rgba(14,162,146,0),rgba(14,162,146,0.2),rgba(14,162,146,0.5));
      position: absolute;
      bottom: 0;
      left: 25%;
      z-index: 0;
      transform-origin: bottom;
      transform: skew(30deg) scaleY(0);
      transition: transform 1s $anime-bezier;
    }
    &::after{
      content: '';
      display: block;
      height: 1px;
      background: linear-gradient(to right,rgba(255,255,255,0),rgba(255,255,255,.8),rgba(255,255,255,0));
      position: absolute;
      bottom: 0;
      left: 100%;
      z-index: 1;
      transition: left 1s $anime-bezier;
      @include res(width,200px);
      @media screen and (max-width: 1440px) {
        @include res(width,160px,(xs:140px));
      }
    }

    &:hover{
      &::before{
        transform: skew(30deg) scaleY(1);
      }
      &::after{
        left: 0%;
      }
    }
    >a{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color-green;
      cursor: default;
      .item-img{
        flex-shrink: 0;
        overflow: hidden;
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        @include res(width,200px);
        @include res(height,200px);
        @include res(margin-right, 15px, 10 / 15);
        @media screen and (max-width: 1440px) {
          @include res(width,150px,(xs:100px));
          @include res(height,150px,(xs:100px));
        }
        img{
          display: block;
          width: 90%;
          height: auto;
        }
      }
      .item-text{
        flex-grow: 1;
        color: rgba(255, 255, 255, 0.6);
        @include res(padding-right, 20px, 10 / 20);
        .title{
          color: #fff;
          font-weight: bold;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
          @include res(font-size, 16px, 15 / 16);
          @include res(margin-bottom, 5px, 0 / 5);
        }
        .desc{
          line-height: 1.4;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
}
//业务荣誉列表---end


//律师列表---start
.common-lawyer-list{
  .lawyer-list-item{
    position: relative;
    overflow: hidden;
    &::before{
      content: '';
      display: block;
      width: 35px;
      height: 100%;
      background: linear-gradient(rgba(14,162,146,0),rgba(14,162,146,0.2),rgba(14,162,146,0.5));
      position: absolute;
      bottom: 0;
      left: 25%;
      z-index: 0;
      transform-origin: bottom;
      transform: skew(30deg) scaleY(0);
      transition: transform 1s $anime-bezier;
    }
    &::after{
      content: '';
      display: block;
      height: 1px;
      background: linear-gradient(to right,rgba(255,255,255,0),rgba(255,255,255,.8),rgba(255,255,255,0));
      position: absolute;
      bottom: 0;
      left: 100%;
      z-index: 1;
      transition: left 1s $anime-bezier;
      @include res(width,200px);
      @media screen and (max-width: 1440px) {
        @include res(width,160px,(xs:140px));
      }
    }

    &:hover{
      &::before{
        transform: skew(30deg) scaleY(1);
      }
      &::after{
        left: 0%;
      }
    }
    a{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid $color-green;
      .item-img{
        flex-shrink: 0;
        overflow: hidden;
        position: relative;
        z-index: 1;
        @include res(width,240px);
        @include res(height,190px);
        // @include res(margin-right, 15px, 10 / 15);
        @media screen and (max-width: 1440px) {
          @include res(width,200px,(xs:160px));
          @include res(height,160px,(xs:130px));
        }
        img{
          display: block;
          width: 100%;
          height: auto;
          margin: 0 auto;
        }
      }
      .item-text{
        flex-grow: 1;
        color: rgba(255, 255, 255, 0.6);
        // @include res(padding-right, 20px, 10 / 20);
        @include res(padding-top, 20px, 10 / 20);
        @media screen and(max-width:1440px) {
          @include res(padding-top, 10px, 0 / 10);
        }
        .title{
          white-space: nowrap;
          @include res(font-size, 16px, (xs:14px));
          @include res(margin-bottom, 10px, 0 / 10);
          span{
            color: #fff;
            // font-weight: bold;
            letter-spacing: 0px;
            display: block;
            line-height: 1;
            @include res(margin-right,10px, 5 / 10);
            @include res(font-size, 22px, 18 / 22);
            white-space: normal;
          }
        }
        .desc{
          line-height: 1.4;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          word-break: break-all;
          white-space: normal !important;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          // white-space: nowrap;
          @include res(height,105px, 54 / 105);
          @include res(font-size, 13px,(xs:13px));
          @media screen and(max-width:1440px) {
            -webkit-line-clamp: 4;
            @include res(height,75px, 70 / 75);
          }
          @media only screen and (width:768px) {
            height: 80px;
          }
        }
      }
    }
  }
}
//律师列表---end


// 关于文康内页第一部分公共样式---start
.about-common-section1{
  @include res(padding-top,115px, 50 / 115);
  @include res(padding-bottom,100px, 50 / 100);
  .cont{
    align-items: flex-start;
    justify-content: space-between;
    @include res(display,flex,(sm:block));
    @include res(padding-left,110px, 0 / 110);
    @include res(padding-right,140px, 0 / 140);
    .left-img{
      flex-shrink: 0;
      @include res(width,32.35%,(sm:100%));
      img{
        display: block;
        width: auto;
        height: auto;
        margin: 0 auto;
        @include res(max-width, 100%,(sm: 30%,xs: 80%));
      }
    }
    .right-text{
      flex-shrink: 0;
      color: #000;
      @include res(line-height, 2);
      @include res(font-size, 16px);
      @include res(width,58.82%,(sm:100%));
      @include res(margin-top,0,(sm:30px,xs:35px));
      h5{
        line-height: 1.5;
        @include res(text-align, left,(sm:center));
        @include res(font-size, 26px, 22 / 26);
        @include res(margin-bottom, 45px, 25 / 45);
      }
    }
  }
}

.common-no-result{
  text-align: center;
  width: 100%;
  @include res(padding-top, 100px, 50 / 100);
  @include res(padding-bottom, 100px, 50 / 100);
  @include res(font-size, 22px, 18 / 22);
}

// 关于文康内页第一部分公共样式---end

.green{
  color: $color-green;
}
.black{
  color: #000;
}
.white{
  color: #fff;
}