@import "../../assets/styles/utils";

.common-list{
  &.blue{
    background-color: $color-blue-dark;
    .ny-common-title{
      color: #fff;
    }
    .list{
      ul{
        li{
          .title{
            color: #fff;
          }
          .desc{
            color: rgba(255,255,255,.8);
          }
        }
      }
    }
  }
  &.autoh{
    .list ul li{
      .title{
        height: auto;
      }
      // .tag{
      //   display: none;
      // }
      .desc{
        height: auto;
      }
    }
  }
  .ny-common-title{
    color: #000;
  }
  .list{
    ul{
      justify-content: space-between;
      flex-wrap: wrap;
      @include res(display,flex);
      li{
        flex-shrink: 0;
        display: none;
        @include res(width,30%,(sm: 48%,xs: 100%));
        @include res(margin-bottom, 60px, 40 / 60);
        @media screen and (min-width: 992px){
          &:nth-child(-n+3){                
              display: block;            
          }
        }
        @media screen and (max-width: 992px){
          &:nth-child(-n+2){                
            display: block;            
        }
        }
        &:hover{
          .title h5{
            background-size: 100% 2px;
          }
        }
        .tag{
          color: $color-green;
          @include res(margin-bottom,15px, 6 / 15);
        }
        .title{
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          color: #000;
          @include res(height, 76px, 54 / 76);
          @include res(font-size, 22px, 18 / 22);
          @include res(margin-bottom, 15px, 6 / 15);
          @media screen and(max-width:767px) {
            height: auto;
          }
          h5{
            display: inline;
            background-image: linear-gradient(to bottom, $color-green, $color-green);
            background-position: 0 calc(100%);
            background-repeat: no-repeat;
            background-size: 0% 2px;
            animation-fill-mode: forwards;
            padding-bottom: 2px;
            transition: background-size 0.7s $anime-bezier;
          }
        }
        .desc{
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          @include res(height, 87px, 68 / 87);
        }
        .date{
          color: $color-green;
          font-size: 12px;
          @include res(margin-top, 15px, 6 / 15);
        }
      }
      .placeholder{
        @include res(width,30%,(sm: 48%,xs: 100%));
      }
    }
  }
  .common-more-btn{
    margin-top: 0;
  }
}