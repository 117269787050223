@import "../../assets/styles/utils";

.loading{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba($color-blue-dark,.79);
  display: none;
  .cont{
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate3d(-50%,-50%,0);
    color: $color-green;
    font-weight: bold;
    img{
      @include res(margin-bottom,10px);
    }
  }
}