@import "../../assets/styles/utils";

.news-banner{
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include res(height, 520px, 400 / 520);
  &.active{
    .container .cont h1::after{
      transform: scaleX(1);
    }
  }
  &.notitle{
    .container{
      opacity: 0;
      visibility: hidden;
    }
  }
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    // background-color: rgba(0,0,0,.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }
  .banner-mask{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: $color-blue-dark;
    opacity: 0;
  }
  .bg-img{
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
  }
  .container{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 100%;
    .cont{
      width: 100%;
      @include res(margin-bottom, 100px, 80 / 100);
      h1{
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 1.3;
        text-align: center;
        @include res(font-size, 50px, 36 / 50);
      }
    }
  }
}