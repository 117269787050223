@import "../../assets/styles/utils";

footer{
  .talk-section{
    // overflow: hidden;
    background-color: $color-blue-dark;
    align-items: center;
    justify-content: center;
    position: relative;
    @include res(display,flex);
    @include res(flex-direction,row,(sm:column));
    @include res(padding-left,10px);
    @include res(padding-right,10px);
    @include res(padding-top,140px, 40 / 140);
    @include res(padding-bottom,140px, 40 / 140);
    .bg-img{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      height: 100%;
      transition: all 1.5s $anime-bezier;
      opacity: 0;
      transform: translateX(-10%);
      &.img-show{
        opacity: 1;
        transform: translateX(0);
      }
      img{
        display: block;
        height: 100%;
        width: auto;
      }
    }
    .left,.right{
      position: relative;
      z-index: 2;
      color: #fff;
    }
    .left{
      cursor: pointer;
      align-items: center;
      @include res(display,flex,(xs:block));
      @include res(margin-right, 115px,(sm:0));
      @include res(margin-bottom, 0,(sm:30px));
      &:hover{
        .title{
          .icon{
            .dian{
              b{
                animation: topdian .2s 1 linear;
                &:nth-child(1){
                  animation-delay: .05s;
                }
                &:nth-child(2){
                  animation-delay: .15s;
                }
                &:nth-child(3){
                  animation-delay: .25s;
                }
              }
            }
          }
        }
      }
      @keyframes topdian{
        0%{
          transform: translateY(0);
        }
        50%{
          transform: translateY(-3px);
        }
        100%{
          transform: translateY(0);
        }
      }
      .title{
        display: flex;
        align-items: center;
        @include res(margin-bottom,0,(xs:15px));
        .icon{
          position: relative;
          @include res(margin-top,14px);
          @include res(margin-right,20px, 10 / 20);
          img{
            display: block;
            height: auto;
            @include res(width,71px, 50 / 71);
          }
          .dian{
            position: absolute;
            z-index: 3;
            top: 45%;
            left: 23%;
            display: flex;
            align-items: center;
            justify-content: center;
            b{
                display: block;
                border-radius: 50%;
                background-color: #cfd7e1;
                margin: 0 2px;
                @include res(width, 6px, 4 / 6);
                @include res(height, 6px, 4 / 6);
            }
        }
        }
        .t{
          font-family: 'itc-dm';
          text-transform: uppercase;
          @include res(font-size, 60px, 34 / 60);
        }
      }
      .desc{
        position: relative;
        @include res(padding-left,26px, 10 / 26);
        @include res(margin-left,26px, 10 / 26);
        &::after{
          content: '';
          display: block;
          width: 1px;
          background-color: #fff;
          opacity: .3;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          @include res(height,48px);
        }
        .t1{
          font-weight: bold;
          @include res(font-size,20px, 16 / 20);
        }
        .t2{
          opacity: .6;
          @include res(font-size, 15px, 12 / 15);
        }
      }
    }
    .right{
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      &:hover{
        .icon{
          &::before{
            transform: translate3d(-50%,-50%,0) scale(1.3);
          }
          &::after{
            transform: translate3d(-50%,-50%,0) scale(1.8);
          }
        }
        .hide-ewm{
          opacity: 1;
          visibility: visible;
          pointer-events: all;
        }
      }
      .t{
        font-weight: bold;
        @include res(font-size,18px, 14 / 18);
      }
      .icon{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @include res(width, 38px);
        @include res(height, 38px);
        @include res(margin-left,20px);
        &::after,&::before{
          content: '';
          display: block;
          width: 160%;
          height: 160%;
          background-color: rgba(255,255,255,.05);
          position: absolute;
          top: 50%;
          left: 50%;
          transition: all .7s $anime-bezier;
          transform: translate3d(-50%,-50%,0);
          border-radius: 50%;
          transform-origin: center;
        }
        &::after{
          width: 300%;
          height: 300%;
          transition-delay: .1s;
          background-color: rgba(255,255,255,.02);
        }
        .iconfont{
          color: $color-green;
          @include res(font-size, 36px, 28 / 36);
        }
      }
      .hide-ewm{
        text-align: center;
        position: absolute;
        line-height: 1.2;
        color: #062745;
        background-color: #fff;
        display: flex;
        z-index: 3;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 1s $anime-bezier,visibility 1s $anime-bezier;
        @include res(padding, 15px, 5 / 15);
        @include res(padding-bottom, 20px, 10 / 20);
        @include res(font-size,14px, 12 / 14);
        @include res(top, 150%);
        @include res(left,50%);
        @include res(transform,translateX(-50%));
        @media screen and (min-width:1900px){
          @include res(top,50%);
          @include res(left,130%);
          @include res(transform,translateY(-50%));
        }
        img{
          display: block;
          height: auto;
          @include res(width,142px, 115 / 142);
        }
      }
    }
  }
  .footer{
    position: relative;
    background-color: #212121;
    border-top: 2px solid $color-green;
    // @include res(display,block,(sm:none));
    @include res(padding-top,60px, 20 / 60);
    @include res(padding-bottom,45px, 15 / 45);
    @include res(padding-left,160px, 20 / 160);
    @include res(padding-right,160px, 20 / 160);
    .back-top{
      background-color: $color-green;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      z-index: 1;
      top: 0;
      font-family: '微软雅黑';
      font-size: 12px;
      text-transform: uppercase;
      line-height: 1.2;
      cursor: pointer;
      @include res(right,65px, 10 / 65);
      @include res(width, 50px);
      @include res(height, 50px);
      &:hover{
        .iconfont{
          transform: translateY(-5px);
        }
      }
      .iconfont{
        font-weight: bold;
        transition: all .4s $anime-bezier;
      }
    }
    .footer-nav{
      align-items: flex-start;
      justify-content: space-between;
      @include res(display,flex,(sm:block));
      .nav-item{
        flex-shrink: 0;
        &:nth-child(3){
          @include res(width,58%,(mmd:64%,md:70%,sm:100%));
          @include res(margin-top,0,(sm:20px));
        }
        &:nth-child(2){
          @include res(width,null,(sm:50%));
          @include res(top,null,(sm:28px,xs:20px));
          @include res(right,null,(sm:0));
          @include res(position,static,(sm:absolute));
          // @include res(transform,translateX(-100%),(mmd:translateX(-80%),md:translateX(-50%),sm:translateX(0)));
        }
        .title{
          display: flex;
          align-items: flex-end;
          &.mob-footer-tit{
            display: none;
          }
          @media (max-width: 767px){
            &.pc-footer-tit{
              display: none;
            }
            &.mob-footer-tit{
              display: flex;
            }
          }
          @include res(margin-bottom,20px, 10 / 20);
          >span{
            font-weight: bold;
            color: #fff;
            @include res(font-size, 20px, 16 / 20);
          }
          .tab{
            font-weight: bold;
            color: #fff;
            @include res(margin-left,20px, 15 / 20);
            span{
              position: relative;
              cursor: pointer;
              @include res(margin-left,20px, 15 / 20);
              &::after{
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background-color: #fff;
                position: absolute;
                left: 0;
                bottom: -2px;
                transform-origin: right;
                transform: scaleX(0);
                transition: transform .4s $anime-bezier;
              }
              &:hover,&.active{
                &::after{
                  transform-origin: left;
                  transform: scaleX(1);
                }
              }
            }
          }
        }
        .cont{
          padding-top: 5px;
          border-left: 1px solid rgba(255,255,255,.2);
          @include res(min-height,240px,(mmd:220px,md:210px,sm:180px));
          // @include res(min-width, 175px);
          @include res(padding-left, 15px, 10 / 15);
          @include res(margin-left, 10px, 10 / 10);
          a{
            color: rgba(255,255,255,.6);
            transition: color .3s $anime-bezier;
            @include res(margin-bottom,7px, 12 / 7);
            @include res(font-size,15px, 14 / 15);
            &:hover{
              color: #fff;
              .ani-line{
                &::after{
                  transform-origin: left;
                  transform: scaleX(1);
                }
              }
            }
            .ani-line{
              padding-bottom: 0;
              line-height: 1.4;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              @include res(width,100%,(xs:95%));
              font-size: 13px;
              &::after{
                height: 1px;
                background-color: #fff;
              }
            }
          }
          >a{
            display: block;
          }
          .swiper-slide{
            display: flex;
            flex-wrap: wrap;
            a{
              flex-shrink: 0;
              @include res(width,25%,(xs:50%));
            }
          }
        }
      }
    }
  }
  .copyright{
    color: rgba(255,255,255,.3);
    background-color: #252525;
    justify-content: space-between;
    @include res(text-align,null,(xs:center));
    @include res(display,flex,(sm:block));
    @include res(padding-left,65px, 10 / 65);
    @include res(padding-right,65px, 10 / 65);
    @include res(padding-top,20px, 10 / 20);
    @include res(padding-bottom,20px, 10 / 20);
    @include res(font-size, 16px, 12 / 16);
    a{
      white-space: nowrap;
      transition: color .7s $anime-bezier;
      &:hover{
        color: rgba(255,255,255,.8);
      }
    }
    .right{
      .site-map{
        @include res(display,null,(md:none));
        @include res(margin-right, 10px);
      }
    }
  }
  .fixed-talk-form{
    .mask{
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 599;
      background-color: rgba(6,39,69,.9);
    }
    .talk-form{
      display: none;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%,-50%,0);
      z-index: 600;
      background-color: #fff;
      width: 95%;
      max-width: 550px;
      box-sizing: border-box;
      @include res(padding-top,50px, 20 / 50);
      @include res(padding-bottom,50px, 10 / 50);
      @include res(padding-left,60px, 15 / 60);
      @include res(padding-right,60px, 15 / 60);
      .close-btn{
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: $color-green;
        @include res(transform,translate3d(50%, -50%,0),(xs:translate3d(20%, -50%,0)));
        @include res(width,60px, 45 / 60);
        @include res(height,60px, 45 / 60);
        &:hover{
          .iconfont{
            transform: rotate(90deg);
          }
        }
        .iconfont{
          transition: transform .7s $anime-bezier;
        }
      }
      .talk-title{
        border-bottom: 1px solid #b4bdc6;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        @include res(padding-bottom, 20px, 10 / 20);
        @include res(margin-bottom, 20px, 10 / 20);
        .left{
          flex-shrink: 0;
          line-height: 1;
          @include res(margin-right,15px, 10 / 15);
          .iconfont{
            color: #cfd7e1;
            @include res(font-size,88px, 50 / 88);
          }
        }
        .right{
          flex-grow: 1;
          line-height: 1.2;
          .t1{
            font-family: 'itc-dm';
            color: $color-blue-dark;
            @include res(font-size,60px, 30 / 60);
          }
          .t2{
            color: $color-desc;
            @include res(font-size,15px, 12 / 15);
          }
        }
      }
      form{
        max-height: 70vh;
        overflow-y: auto;
        padding: 0 3px;
        .form-item{
          @include res(margin-bottom,20px, 10 / 20);
          label{
            display: block;
            padding-left: 5px;
            @include res(margin-bottom, 10px, 5 / 10);
            span{
              color: $color-green;
              margin-left: 3px;
            }
          }
          input,textarea{
            width: 100%;
            border: 1px solid #cedcdc;
            border-radius: 3px;
            outline: 3px solid transparent;
            transition: all .7s $anime-bezier;
            @include res(padding-left, 10px);
            @include res(padding-right, 10px);
            &:focus{
              border: 1px solid $color-green;
              outline: 3px solid #a8d5d5;
            }
          }
          input{
            @include res(height,50px, 35 / 50);
          }
          textarea{
            @include res(height,105px, 80 / 105);
            @include res(padding-top, 10px);
            @include res(padding-bottom, 10px);
          }
        }
        .form-btn{
          button{
            width: 100%;
            background-color: $color-blue-dark;
            color: #fff;
            border-radius: 3px;
            letter-spacing: 0px;
            transition: all .7s $anime-bezier;
            border: none;
            cursor: pointer;
            @include res(height,48px,40 / 48);
            &:hover{
              background-color: $color-green;
            }
          }
        }
      }
    }
  }
}