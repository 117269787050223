@font-face {
  font-family: "iconfont"; /* Project id 3657286 */
  src: url('iconfont.woff2?t=1672127047367') format('woff2'),
       url('iconfont.woff?t=1672127047367') format('woff'),
       url('iconfont.ttf?t=1672127047367') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-31dianhua:before {
  content: "\e601";
}

.icon-icon-1:before {
  content: "\e620";
}

.icon-head:before {
  content: "\e616";
}

.icon-zhuanfa:before {
  content: "\e72b";
}

.icon-youxiang:before {
  content: "\e908";
}

.icon-icon-:before {
  content: "\e747";
}

.icon-resume-line:before {
  content: "\e64a";
}

.icon-telephone:before {
  content: "\e953";
}

.icon-shipinbofang:before {
  content: "\e604";
}

.icon-xiazai:before {
  content: "\e668";
}

.icon-pdf-fill:before {
  content: "\e787";
}

.icon-gengduo:before {
  content: "\e600";
}

.icon-guanbi:before {
  content: "\eaf2";
}

.icon-sey-talk-d-a:before {
  content: "\e66a";
}

.icon-arrow3:before {
  content: "\e62a";
}

.icon-weixin-copy:before {
  content: "\e654";
}

.icon-search_light:before {
  content: "\e7da";
}

.icon-arrow-up:before {
  content: "\e743";
}

.icon-arrow-down:before {
  content: "\e7b2";
}

.icon-arrow-downward:before {
  content: "\e855";
}

.icon-arrow-right:before {
  content: "\eaf3";
}

.icon-arrow-left:before {
  content: "\eaf4";
}

