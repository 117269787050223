﻿//背景用色
$color-green:#0ea292; //主题绿色
$color-blue:#2d7294;
$color-blue-dark:#062745; 
$color-bg:#eaedf0;

//border用色
$color-line:#bbbec0;

//文字用色
$color-red:#ff0909; //错误提示文字颜色
$color-title:#000; //标题黑色
$color-text:#585858; //正文颜色  80%黑
$color-desc:#999999; //解释性文字颜色  60%黑

//文字大小
$text-size-sm-base:14px;
$text-size-sm-ratio:12 / 14;

$text-size-base:16px; //正文大小
$text-size-ratio:(lg:14px); //缩放

$text-size-md-base:18px; //列表头条内容文字大小
$text-size-md-ratio:(lg:16px, mmd:14px); //缩放

$text-size-lg-base:20px;
$text-size-lg-ratio:(lg:18px, mmd:16px);

//tag文字大小
$tag-size-base: 16px;
$tag-size-ratio: 14 / 16;


// 动画效果持续时间
$anime-duration: 0.7s;
//贝塞尔曲线
$anime-bezier:cubic-bezier(0.23, 1, 0.32, 1);

//header高度
$header-height-base: 150px;
$header-height-ratio: 60 / 150;

//内容部分左右留白
$container-gutter-base:80px;
$container-gutter-ratio:30 / 80;

//阴影
$shadow-offset: 0px 3px;
$shadow-spread: 10px;
$shadow-color:#dbdada;

$jason-map-width: 400px !default;
$jason-map-duration: .5s !default;
$jason-map-theme: $color-green !default;

//汉堡包按钮参数设置
$hamburger-layer-width: 23px; //汉堡包按钮每一条线的宽度
$hamburger-layer-height: 1px; //汉堡包按钮每一条线的高度
$hamburger-layer-spacing: 4px; //汉堡包按钮每一条线的高度间隔
$hamburger-layer-color: #fff; //汉堡包按钮线的颜色
$hamburger-layer-border-radius: 0; //汉堡包按钮线的圆角效果

// 响应式断点，需要从小到大写
$layout-responsive-breakpoint: ( // 手机
  xs: (min-width: 0px, container-width: 100%),
  // 平板
  sm: (min-width: 768px, container-width: 720px),
  // 小屏
  md: (min-width: 992px, container-width: 960px),
  // 中屏
  mmd: (min-width: 1025px, container-width: 1000px),
  // 大屏
  lg: (min-width: 1367px, container-width: 1230px),

  llg: (min-width: 1601px, container-width: 1230px)) !default;
